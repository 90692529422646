<template>
    <!-- Заголовок Новости -->
<section class="w-full flex-grow sm:flex sm:items-center sm:w-auto mt-2">
    <div class="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm uppercase mt-0 px-6 py-2">
        <h2 class="p-4 pb-0 text-4xl uppercase font-Intro">Новости магазина</h2>
    </div>

</section>
    <div class="container flex flex-wrap py-6 px-0 md:px-4 max-w-full md:max-w-2xl lg:max-w-4xl 2xl:max-w-6xl">

        <!-- Posts Section -->
        <section class="w-full flex flex-col items-center px-0 md:px-3 mx-auto">

            <BlogPost_news6 />
            <BlogPost_news5 />
            <BlogPost_news4 />
            <BlogPost_news3 />
            <BlogPost_news1 />
            <BlogPost_news2 />
            
            <!-- <BlogPost /> -->
            <!-- <BlogPost2 /> -->

        

            <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <!-- <a href="#" class="hover:opacity-75 flex">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=2" class="grow">
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <div><a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Садовая техника</a> | <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Триммеры</a></div>
                    <a href="#" class="font-Oswald text-3xl font-medium hover:text-gray-700 pb-4">GARDENA ClassicCut Li 09855-20.000.00</a>
                    <p href="#" class="pb-3 text-sm">
                        Апрель 25, 2020
                    </p>
                    <a href="#" class="pb-6 max-h-40 overflow-hidden gradient-mask-b-0">
                        Аккумуляторные ножницы для травы GARDENA ClassicCut Li 09855-20.000.00 благодаря эргономичной рукоятке ножницы удобно лежат в руке.

                        Замена лезвия без инструмента упрощает обслуживание инструмента.
                        
                        Благодаря телескопической рукоятке можно стричь кромки в комфортном положении.
                        
                        Аккумулятор можно быстро перезарядить в любой время без эффекта памяти.
                        Преимущества Gardena ClassicCut Li 09855-20.000.00
                        <p> Аккумуляторный двигатель - для независимости от розетки;
                        Прецизионная заточка лезвий - для износостойкости GARDENA ClassicCut Li 09855-20.000.00;
                        Замена оснастки без усилий - для простого использования;
                        Телескопическая ручка - для работы стоя;
                        Индикаторы заряда аккумулятора - для своевременного выключения из сети;
                        Эргономичная рукоять - для надежного охвата;
                        Оптимальная геометрия лезвия - для быстрой стрижки;
                        Li-ion аккумулятор не требует дополнительного обслуживания.
                        </p>
                    </a>
                    <a href="#" class="text-slate-800 hover:text-black uppercase">Читать далее <i class="fas fa-arrow-down"></i></a>
                </div> -->
            </article>

            <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <!-- <a href="#" class="hover:opacity-75 flex">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=3" class="grow">
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <div><a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Садовая техника</a> | <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Триммеры</a></div>
                    <a href="#" class="font-Oswald text-3xl font-medium hover:text-gray-700 pb-4">GARDENA ClassicCut Li 09855-20.000.00</a>
                    <p href="#" class="pb-3 text-sm">
                        Апрель 25, 2020
                    </p>
                    <a href="#" class="pb-6 max-h-40 overflow-hidden gradient-mask-b-0">
                        Аккумуляторные ножницы для травы GARDENA ClassicCut Li 09855-20.000.00 благодаря эргономичной рукоятке ножницы удобно лежат в руке.

                        Замена лезвия без инструмента упрощает обслуживание инструмента.
                        
                        Благодаря телескопической рукоятке можно стричь кромки в комфортном положении.
                        
                        Аккумулятор можно быстро перезарядить в любой время без эффекта памяти.
                        Преимущества Gardena ClassicCut Li 09855-20.000.00
                        <p> Аккумуляторный двигатель - для независимости от розетки;
                        Прецизионная заточка лезвий - для износостойкости GARDENA ClassicCut Li 09855-20.000.00;
                        Замена оснастки без усилий - для простого использования;
                        Телескопическая ручка - для работы стоя;
                        Индикаторы заряда аккумулятора - для своевременного выключения из сети;
                        Эргономичная рукоять - для надежного охвата;
                        Оптимальная геометрия лезвия - для быстрой стрижки;
                        Li-ion аккумулятор не требует дополнительного обслуживания.
                        </p>
                    </a>
                    <a href="#" class="text-slate-800 hover:text-black uppercase">Читать далее <i class="fas fa-arrow-down"></i></a>
                </div> -->
            </article>

            <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <!-- <a href="#" class="hover:opacity-75 flex">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=2" class="grow">
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Automotive, Finance</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">Lorem Ipsum Dolor Sit Amet Dolor Sit Amet</a>
                    <p href="#" class="text-sm pb-3">
                        By <a href="#" class="font-semibold hover:text-gray-800">David Grzyb</a>, Published on January 12th, 2020
                    </p>
                    <a href="#" class="pb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis porta dui. Ut eu iaculis massa. Sed ornare ligula lacus, quis iaculis dui porta volutpat. In sit amet posuere magna..</a>
                    <a href="#" class="uppercase text-gray-800 hover:text-black">Continue Reading <i class="fas fa-arrow-right"></i></a>
                </div> -->
            </article>

            <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <!-- <a href="#" class="hover:opacity-75 flex">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=3" class="grow">
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Sports</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">Lorem Ipsum Dolor Sit Amet Dolor Sit Amet</a>
                    <p href="#" class="text-sm pb-3">
                        By <a href="#" class="font-semibold hover:text-gray-800">David Grzyb</a>, Published on October 22nd, 2019
                    </p>
                    <a href="#" class="pb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis porta dui. Ut eu iaculis massa. Sed ornare ligula lacus, quis iaculis dui porta volutpat. In sit amet posuere magna..</a>
                    <a href="#" class="uppercase text-gray-800 hover:text-black">Continue Reading <i class="fas fa-arrow-right"></i></a>
                </div> -->
            </article>

            <!-- Load more -->
            <!-- <div class="flex items-center py-8">
                <a href="#" class="h-10 w-25 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center px-4">Загрузить ещё <i class="fas fa-arrow-down ml-2"></i></a>
            </div> -->

        </section>

    </div>
</template>

<script>
import BlogPost from '@/components/layout/BlogPost'
import BlogPost2 from '@/components/layout/BlogPost2'
import BlogPost_news1 from './BlogPost_news1.vue';
import BlogPost_news2 from './BlogPost_news2.vue';
import BlogPost_news3 from './BlogPost_news3.vue';
import BlogPost_news4 from './BlogPost_news4.vue';
import BlogPost_news5 from './BlogPost_news5.vue';
import BlogPost_news6 from './BlogPost_news6.vue';

export default {
    data: () => {
        return {
            readMore: false
        }
    },
    name: 'BlogTape',
    components: {
    BlogPost,
    BlogPost2,
    BlogPost_news1,
    BlogPost_news2,
    BlogPost_news3,
    BlogPost_news4,
    BlogPost_news5,
    BlogPost_news6,
}
}
</script>